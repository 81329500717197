import { useRef } from 'react'

import CustomImage from '../images/image'

export const HireButton = ({ text, className, onClick, disabled }) => {
  const worldRef = useRef()
  const waveRef = useRef()

  const onMouseEnter = () => {
    worldRef.current.className = 'hire-icon-world icon-transition'
    waveRef.current.className = 'hire-wave icon-transition'
  }
  const onMouseLeave = () => {
    worldRef.current.className = 'hire-icon-world'
    waveRef.current.className = 'hire-wave'
  }

  return (
    <button
      type="button"
      className={`hire-button ${className}`}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="hire-icon">
        <div className="hire-icon-world" ref={worldRef}>
          <CustomImage
            src="/icons/world.svg"
            alt="button icon"
            width={500}
            height={500}
          />
        </div>
        <span className="hire-wave" ref={waveRef}>
          <p id="wave-icon">👋🏻</p>
        </span>
      </div>
      <div className="button-label">{text}</div>
    </button>
  )
}
