import { ArcElement, Chart } from 'chart.js'
import Link from 'next/link'
import { memo, useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { chartColors } from 'utils/constants'

import { HireButton } from '@/components/elements/buttons/hireButton'
import { HeaderBackground } from '@/components/elements/headerBackground'

Chart.register(ArcElement)

const options = {
  plugins: {
    legend: { display: false },
    tooltip: { enabled: false }
  },
  layout: { padding: 50 },
  responsive: true
}

const HeaderHomePage = ({ data }) => {
  const { title, subtitle, button, chartTitle, chartSections, totalClients } = data
  const countries = chartSections.map(item => item.country)
  const clients = chartSections.map(item => item.clients)
  const colors = chartColors

  const userData = useMemo(
    () => ({
      labels: countries,
      datasets: [
        {
          label: 'Percent',
          data: clients,
          backgroundColor: colors,
          hoverOffset: 50,
          radius: 115,
          cutout: '50%',
          spacing: 3
        }
      ]
    }),
    [chartSections]
  )

  const plugins = useMemo(
    () => [
      {
        id: 'text',
        beforeDraw: function (chart) {
          var ctx = chart.ctx

          ctx.restore()
          ctx.font = '900 25px sans-serif'
          ;(ctx.textBaseline = 'middle'), ctx.save()
        }
      }
    ],
    [totalClients]
  )

  return (
    <div className="header-home-page-wrapper">
      <div className="header-home-page-background-svg-container">
        <HeaderBackground className="header-home-page-background-svg" />
      </div>
      <div className="header-home-page-content-container">
        <div className="header-home-page-info-container">
          <div className="header-home-page-title">{title}</div>
          <div className="header-home-page-subtitle">{subtitle}</div>
          <Link href="/contact">
            <HireButton className="header-home-page-button" text={button} type="button" />
          </Link>
        </div>
        <div className="header-home-page-chart-container">
          <div className="header-home-page-chart-subcontainer">
            <div className="header-home-page-chart-title">{chartTitle}</div>
            <div className="header-home-page-chart">
              <div className="doughnut-chart">
                <Doughnut data={userData} options={options} plugins={plugins} />
              </div>
              <div className="header-home-page-chart-legend">
                {chartSections.map((section, index) => (
                  <div key={section.id}>
                    <div className="header-home-page-chart-legend-title">
                      <div style={{ color: colors[`${index}`], fontWeight: 800 }}>O</div>
                      {section.country}
                    </div>
                    <div className="header-home-page-chart-legend-value">
                      {section.clients}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(HeaderHomePage)
